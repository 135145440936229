import React from 'react';
import { withTranslation } from "react-i18next";
import ProductConfigHelper from "../../../../utils/helpers/product-config-helper";
import Dropdown from 'react-dropdown';

class AppAdvertisingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adsId: props.ad?.adUnitId,
      adsEnabled: props.ad?.enabled,
      title: props.title,
      placeholder: props.placeholder,
      source: props.ad?.source,
      duration: props.ad?.duration,
      durationOfEachAd: props.ad?.durationOfEachAd,
      images: this.initArray(props.ad?.images || []),
      videos: this.initArray(props.ad?.videos || [])
    };
  }

  initArray = (values) => {
    const items = Array(3).fill("");
    return items.map((valor, index) => valor || values[index] || "");
  }

  render() {
    return (
      <div className="input-container" style={{ marginBottom: "30px" }}>
        <div className="two-col" onClick={this.handleAdsEnableChange}>
          <label>{this.state.title}</label>
          <div >
            {this.state.adsEnabled ? (
              <i className="bi bi-toggle-on" />
            ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
        </div>

        {this.state.adsEnabled && (
          <div>

            <div style={{ marginTop: "10px" }}>
              <label className="two-col">Source</label>
              <Dropdown
                options={ProductConfigHelper.adSources()}
                onChange={this.handleSourceChange}
                value={this.state.source} />

            </div>

            <div style={{ marginTop: "10px", marginBottom: "30px" }}>
              <label>Duration</label>
              <input
                onChange={this.handleDurationChange}
                value={this.state.duration}
                type="number"
                className="form-input"
                placeholder="30"
              />

            </div>

            {(this.state.source == 'GOOGLE') && (

              <div style={{ marginTop: "10px", marginBottom: "30px" }}>
                <label>Duration of each</label>
                <input
                  onChange={this.handleDurationOfEachChange}
                  value={this.state.durationOfEachAd}
                  type="number"
                  className="form-input"
                  placeholder="10"
                />

              </div>
            )}


            {(this.state.source == 'GOOGLE' || this.state.source == 'START_IO') && (

              <input
                readOnly={!this.state.adsEnabled}
                value={this.state.adsId}
                type="text"
                className="form-input"
                placeholder={this.state.placeholder}
                onChange={(event) => this.handleAdsIdChange(event)}
              />
            )}

            {(this.state.source == 'GLOBETEK') && (

              <div>
                <div style={{ marginTop: "10px", marginBottom: "30px" }}>
                  <label>Images</label>
                  {this.state.images.map((str, index) => (
                    <div key={index}>
                      <input
                        value={str}
                        type="text"
                        className="form-input"
                        placeholder="https://"
                        onChange={(event) => this.handleImagesChange(index, event.target.value)}
                      />
                    </div>
                  ))}

                </div>

                <div style={{ marginTop: "10px", marginBottom: "30px" }}>
                  <label>Videos</label>
                  {this.state.videos.map((str, index) => (
                    <div key={index}>
                      <input
                        value={str}
                        type="text"
                        className="form-input"
                        placeholder="https://"
                        onChange={(event) => this.handleVideosChange(index, event.target.value)}
                      />
                    </div>
                  ))}

                </div>
              </div>
            )}

          </div>
        )}

      </div>
    );
  }

  setPlaceHolder = (source) => {
    let placeholder = ""
    if (source == 'GOOGLE') {
      placeholder = "ca-app-pub-0000000000000000/0000000000"
    } else if (source == 'START_IO') {
      placeholder = "000000000"
    }

    this.setState({
      placeholder: placeholder
    });
  }

  handleAdsIdChange = (event) => {

    this.setState({
      adsId: event.target.value
    });

    let ads = {
      enabled: this.state.adsEnabled,
      adUnitId: event.target.value,
      source: this.state.source,
      images: this.state.images,
      videos: this.state.videos,
      duration: this.state.duration,
      durationOfEachAd: this.state.durationOfEachAd
    }

    this.props.handle(ads)
  };

  handleAdsEnableChange = (event) => {

    let isChecked = !this.state.adsEnabled

    this.setState({ adsEnabled: isChecked });

    let ads = {
      enabled: isChecked,
      adUnitId: this.state.adsId,
      source: this.state.source,
      images: this.state.images,
      videos: this.state.videos,
      duration: this.state.duration,
      durationOfEachAd: this.state.durationOfEachAd
    }

    this.props.handle(ads)
  }

  handleSourceChange = (event) => {

    this.setState({
      source: event.value,
    });

    let ads = {
      enabled: this.state.adsEnabled,
      adUnitId: this.state.adsId,
      source: event.value,
      images: this.state.images,
      videos: this.state.videos,
      duration: this.state.duration,
      durationOfEachAd: this.state.durationOfEachAd
    }

    this.setPlaceHolder(event.value)
    this.props.handle(ads)
  };

  handleImagesChange = (index, value) => {
    const vs = this.state.images
    vs[index] = value

    this.setState({
      images: vs
    })

    let ads = {
      enabled: this.state.adsEnabled,
      adUnitId: this.state.adsId,
      source: this.state.source,
      images: vs,
      videos: this.state.videos,
      duration: this.state.duration,
      durationOfEachAd: this.state.durationOfEachAd
    }

    this.props.handle(ads)
  };

  handleVideosChange = (index, value) => {
    const vs = this.state.videos
    vs[index] = value

    this.setState({
      videos: vs
    })

    let ads = {
      enabled: this.state.adsEnabled,
      adUnitId: this.state.adsId,
      source: this.state.source,
      images: this.state.images,
      videos: vs,
      duration: this.state.duration,
      durationOfEachAd: this.state.durationOfEachAd
    }

    this.props.handle(ads)
  };

  handleDurationChange = (event) => {

    this.setState({
      duration: event.target.value
    });

    let ads = {
      enabled: this.state.adsEnabled,
      adUnitId: this.state.adsId,
      source: this.state.source,
      images: this.state.images,
      videos: this.state.videos,
      duration: event.target.value,
      durationOfEachAd: this.state.durationOfEachAd
    }

    this.props.handle(ads)
  };

  handleDurationOfEachChange = (event) => {

    this.setState({
      durationOfEachAd: event.target.value
    });

    let ads = {
      enabled: this.state.adsEnabled,
      adUnitId: this.state.adsId,
      source: this.state.source,
      images: this.state.images,
      videos: this.state.videos,
      duration: this.state.duration,
      durationOfEachAd: event.target.value
    }

    this.props.handle(ads)
  };
}

const AppAdvertisingFormTranslated = withTranslation()(AppAdvertisingForm)

export default AppAdvertisingFormTranslated
