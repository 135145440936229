import React from 'react';
import Button from "../../../ui/button/button";
import ProductConfigHelper from "../../../../utils/helpers/product-config-helper";
import ToastHelper from "../../../../utils/helpers/toast-helper";
import PartnerConfigService from "../../../../service/partner-config-service";
import PartnerActionHelper from "../../../../utils/helpers/partner-action-helper";

function ButtonSavePartner({updateFormData, formData}) {

  function cleanupForm() {
    /* Basic information */
    updateFormData("name", null);
    updateFormData("email", null);
    updateFormData("activationFee", 0);
    updateFormData("maintenanceFee", 0);

    /* App visual */
    updateFormData("appIcon", null);
    updateFormData("logo", null);
    updateFormData("backgroundColor", "#000000");

    /* App content */
    updateFormData("phoneNumber", null);
    updateFormData("helpPage", null);
    updateFormData("lockMessage", null);

    /* Advanced settings */
    updateFormData("lockType", ProductConfigHelper.lockTypes()[0].value);
    updateFormData("webhook", null);
    updateFormData("webhookUrlCustomer", null);
    updateFormData("webhookUrlUploadDevice", null);
    updateFormData("appConfiguration", null);
    updateFormData("temporaryUnlock", null);
    updateFormData("knoxGuard", null);
    updateFormData("push", null);

    updateFormData("preLocked", false);
    updateFormData("preCreateCollateral", false);
    updateFormData("hasBiometric", false);
    updateFormData("configurationKG", null);
  }

  function isTheFormValid() {
    const requiredFields = ["name", "email", "appIcon", "logo"]

    for (const field of requiredFields) {
      if (formData[field] === null) {
        ToastHelper.showError('Please fill in all required fields')
        return false;
      }
    }
    return true;
  }

  async function createPartner() {
    const partnerData = new FormData();

    let partner = {
      'name': formData.name,
      'helpPage': formData.helpPage,
      'phoneNumber': formData.phoneNumber,
      'backgroundColor': formData.backgroundColor,
      'lockType': formData.lockType,
      'email': formData.email,
      'lockMessage': formData.lockMessage,
      'webhook': formData.webhook,
      'webhookUrlCustomer': formData.webhookUrlCustomer,
      'webhookUrlUploadDevice': formData.webhookUrlUploadDevice,
      'preLocked': formData.preLocked,
      'preCreateCollateral': formData.preCreateCollateral,
      'hasBiometric': formData.hasBiometric,
      'configurationKG': formData.configurationKG,

      'appConfiguration': formData.appConfiguration
    };

    partnerData.append('partner', new Blob([JSON.stringify(partner)], {type: 'application/json'}));
    partnerData.append('logo', formData.logo);
    partnerData.append('appIcon', formData.appIcon);

    try {
      await ToastHelper.showLoading(
        PartnerConfigService.createPartner(partnerData, formData.activationFee, formData.maintenanceFee),
        {
          error: {
            message: 'Error',
            conflict: 'Conflict'
          },
          success: 'Success',
          pending: 'Pending'
        }
      );
      return 200;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  function handlePartnerData(partnerData){
    let partner = {
      'name': formData.name,
      'partnerCode': formData.partnerCode,
      'logo': formData.logo,
      'helpPage': formData.helpPage,
      'phoneNumber': formData.phoneNumber,
      'backgroundColor': formData.backgroundColor,
      'lockType': formData.lockType,
      'email': formData.email,
      'lockMessage': formData.lockMessage,
      'webhook': formData.webhook,
      'webhookUrlCustomer': formData.webhookUrlCustomer,
      'webhookUrlUploadDevice': formData.webhookUrlUploadDevice,
      'appConfiguration': formData.appConfiguration,
      'updateLogo': formData.updateLogo,
      'appIcon': formData.appIcon,
      'appIconMdpiUrl': formData.appIconMdpiUrl,
      'appIconHdpiUrl': formData.appIconHdpiUrl,
      'appIconXhdpiUrl': formData.appIconXhdpiUrl,
      'appIconXxhdpiUrl': formData.appIconXxhdpiUrl,
      'appIconXxxhdpiUrl': formData.appIconXxxhdpiUrl,
      'updateAppIcon': formData.updateAppIcon,
      'ads': formData.ads,
      'version': formData.version,
      'temporaryUnlock': formData.temporaryUnlock,
      'knoxGuard': formData.knoxGuard,
      'push': formData.push,
      'preLocked': formData.preLocked,
      'preCreateCollateral': formData.preCreateCollateral,
      'hasBiometric': formData.hasBiometric,
      'configurationKG': formData.configurationKG,
    };

    partnerData.append('partner', new Blob([JSON.stringify(partner)], {type: 'application/json'}));
    partnerData.append('logo', formData.logo);
    partnerData.append('appIcon', formData.appIcon);

    return partnerData
  }

  async function updatePartner() {
    const partnerData = new FormData();

    handlePartnerData(partnerData)

    try {
      await ToastHelper.showLoading(
        PartnerConfigService.updatePartner(partnerData),
        {
          error: {
            message: 'Error',
            conflict: 'Conflict'
          },
          success: 'Success',
          pending: 'Pending'
        }
      );
      return 200;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  function handleSubmit() {
    if (isTheFormValid()) {

      if (formData.action === PartnerActionHelper.CREATE){
        createPartner()
          .then((response) => {
            if (response === 200)
              cleanupForm()
          })
      }
      if (formData.action === PartnerActionHelper.UPDATE){
        updatePartner()
      }
    }
  }

  return (
    <Button label={'Submit'} onClick={handleSubmit}/>
  );
}

export default ButtonSavePartner;
