import React from 'react';
import { withTranslation } from "react-i18next";
import { Tooltip } from '@material-ui/core';

class AppWebhookKGForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webhookUrlCustomer: props.webhookUrlCustomer,
      webhookUrlCustomerAux: props.webhookUrlCustomer,
      webhookUrlUploadDevice: props.webhookUrlUploadDevice,
      webhookUrlUploadDeviceAux: props.webhookUrlUploadDevice,
      showWebhookKG: (props.webhookUrlCustomer != null && props.webhookUrlCustomer.length > 0) || (props.webhookUrlUploadDevice != null && props.webhookUrlUploadDevice.length > 0)
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container" style={{ marginTop: "40px" }}>
        <label>Knox Guard</label>
        <div className="two-col" style={{ marginTop: "40px" }} onClick={this.handleShowWebhookChange}>
          <label>{t('custom.webhook_kg_label')}</label>
          <div>
            {this.state.showWebhookKG ? (
              <i className="bi bi-toggle-on" />
            ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
          <Tooltip style={{ backgroundColor: 'transparent' }} title={t('custom.webhook_kg_tooltip')} placement="top">
            <i className="bi bi-info-circle-fill"></i>
          </Tooltip>
        </div>

        {this.state.showWebhookKG ? (

          <div>

            <label>Device created callback</label>
            <input
              type="text"
              className="form-input"
              value={this.state.webhookUrlCustomer}
              placeholder={t('custom.webhook_place_holder')}
              onChange={this.handleWebhookUrlCustomerChange}
            />

            <label>Device creation validation</label>
            <input
              type="text"
              className="form-input"
              value={this.state.webhookUrlUploadDevice}
              placeholder={t('custom.webhook_place_holder')}
              onChange={this.handleWebhookUrlUploadDeviceChange}
            />
          </div>


        ) :
          <div style={{ marginTop: '30px', marginBottom: '30px' }} />
        }
      </div>
    );
  }

  handleWebhookUrlCustomerChange = (event) => {
    this.setState({
      webhookUrlCustomer: event.target.value,
      webhookUrlCustomerAux: event.target.value,
    });
    this.props.handle(
      event.target.value,
      this.state.webhookUrlUploadDevice
    )
  };

  handleWebhookUrlUploadDeviceChange = (event) => {
    this.setState({
      webhookUrlUploadDevice: event.target.value,
      webhookUrlUploadDeviceAux: event.target.value,
    });
    this.props.handle(
      this.state.webhookUrlCustomer,
      event.target.value
    )
  };

  handleShowWebhookChange = () => {

    let isChecked = !this.state.showWebhookKG

    this.setState({ showWebhookKG: isChecked });

    if (isChecked) {
      this.setState({
        webhookUrlCustomer: this.state.webhookUrlCustomerAux,
        webhookUrlUploadDevice: this.state.webhookUrlUploadDeviceAux
      });
      this.props.handle(
        this.state.webhookUrlCustomerAux,
        this.state.webhookUrlUploadDeviceAux
      )
    } else {
      this.setState({
        webhookUrlCustomer: "",
        webhookUrlUploadDevice: ""
      });
      this.props.handle("", "")
    }
  }
}

const AppWebhookKGFormTranslated = withTranslation()(AppWebhookKGForm)

export default AppWebhookKGFormTranslated
