import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import LoginComponent from 'components/login/login-component';
import * as Authentication from 'utils/helpers/authentication-helper';
import routes from 'navigation/routes';
import PartnerReports from '../pages/main/partner-reports';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authority = rest.authority === undefined ? true : rest.authority;
  return (
    <Route
      {...rest}
      render={(props) => {
        return Authentication.isAuthenticated() ? (
          authority ? (
            <Component {...props} />
          ) : (
            <Redirect to={routes.REPORTS} component={PartnerReports} />
          )
        ) : (
          <Redirect to={routes.LOGIN} component={LoginComponent} />
        );
      }}
    />
  );
};

export default PrivateRoute;
