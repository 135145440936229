import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PartnerService from '../../service/partner-config-service';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/_imeiDemo.css';
import LittleLoadingComponent from 'components/ui/loading/little-loading-component';
import IconDialogClose from '../../assets/images/icon_dialog_close.png';
import IconDialogHeader from '../../assets/images/icon_dialog_header.png';
import IconDialogDeviceAbout from '../../assets/images/icon_device_about.png';
import IconDialogDeviceIMEI from '../../assets/images/icon_device_imei.png';
import IconDialogDeviceSettings from '../../assets/images/icon_device_settings.png';
import ImgDialogScreenImei from '../../assets/images/screen_imei.png';
import IconDialogHelp from '../../assets/images/icon_imei_help.png';
import IconDialogCheck from '../../assets/images/icon_check.png';

function ImeiDemo() {
  const { productCode } = useParams();
  const { t, i18n } = useTranslation();
  const [logo, setLogo] = useState('');
  const [loading, setLoading] = useState(true);
  const [imei, setImei] = useState('');
  const [userId, setUserId] = useState('');
  const [isFetched, setIsFetched] = useState(false);
  const [showSecondScreen, setShowSecondScreen] = useState(false);
  const [showThirdScreen, setShowThirdScreen] = useState(false);
  const [errors, setErrors] = useState({ imei: '', userId: '' });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showDialogIMEI, setShowDialogIMEI] = useState(false);
  const [showDialogHelp, setShowDialogHelp] = useState(false);

  const validateInputs = () => {
    if (!imei || imei.length !== 15) {
      setErrors((prev) => ({ ...prev, imei: t('imei_demo.invalid_imei') }));
      toast.error(t('imei_demo.invalid_imei'));
      return false;
    }

    const cleanedUserId = userId.replace(/\D/g, '');
    if (!cleanedUserId || cleanedUserId.length !== 11) {
      setErrors((prev) => ({ ...prev, document: t('imei_demo.invalid_cpf') }));
      toast.error(t('imei_demo.invalid_cpf'));
      return false;
    }

    return true;
  };

  useEffect(() => {
    i18n.changeLanguage('pt');
  }, [i18n]);


  useEffect(() => {
    const fetchPartnerData = async () => {
      try {
        const response = await PartnerService.findCustomInterfaceByProductCode(productCode);
        if (response?.data) {
          const appIcon = response.data.logo;
          setLogo(appIcon);
        }
      } catch (error) {
        setLogo();
      } finally {
        setLoading(false);
      }
    };

    if (!isFetched) {
      fetchPartnerData();
      setIsFetched(true);
    }
  }, [isFetched, productCode]);

  const handleValidateUploadUser = async (e) => {

    e.preventDefault();
    if (!validateInputs()) return;

    setLoadingSubmit(true);
    setIsButtonDisabled(true);

    const payload = {
      "imei": imei,
      "productCode": productCode,
      "userId": userId,
    };

    try {
      const response = await PartnerService.validateUploadUser(payload);
      if (response.status !== 200) throw new Error();

      toast.success(t('imei_demo.validation_success'));

      setShowSecondScreen(true);
      setLoadingSubmit(false);

    } catch (error) {

      setIsButtonDisabled(false);
      setLoadingSubmit(false);

      try {

        if (error.response.status == 404) {
          toast.error("Verifique o IMEI e o CPF");

        } else {

          const errorData = error.response.data[0]
          if (errorData.code === 'device.imei.already.registered')
            toast.error("IMEI já está cadastrado");

          else if (errorData.code === 'person.not.found')
            toast.error("Verifique o IMEI e o CPF");

          else
            toast.error(t('imei_demo.validation_failed'));

        }

      } catch (error) {
        toast.error(t('imei_demo.validation_failed'));
      }

      return false;
    }
  };

  const handleActiveClick = () => {
    const url = 'smdm://umc.html?program=BULK_ENROLL&service_type=KG';
    window.location.href = url;

    setLoadingSubmit(true);

    setTimeout(() => {

      setShowThirdScreen(true);
      setLoadingSubmit(false);

    }, 2000);
  };

  const handleImeiChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 15);
    setImei(value);
    if (value.length === 15) {
      setErrors((prev) => ({ ...prev, imei: '' }));
    }
  };

  const handleDocumentChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 15);
    setUserId(value);
    if (value.length === 11) {
      setErrors((prev) => ({ ...prev, document: '' }));
    }
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loaderr"></div>
        <p className="loading-text" style={{ marginTop: '50px' }}>
          {t('imei_demo.loading')}
          <span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </p>
      </div>
    );
  }

  // Third screen
  if (showThirdScreen) {
    return (
      <div className="imei-container">
        <main className="imei-main">
          <header className="imei-header">
            <img src={logo} alt="Logo do parceiro" className="imei-logo" />
          </header>
          <p style={{ lineHeight: 1.3 }}>
            Garantia ativada com sucesso.
            <br /><br />
            Agora você será levado automaticamente para o aplicativo SuperSim Proteção.
            <br /><br />
            Se isso não acontecer em até 10 segundos, feche esta tela e procure pelo aplicativo SuperSim Proteção no seu celular.
          </p>
          <button
            className="button-86"
            role="button"
            style={{ marginTop: '40px' }}
            onClick={handleActiveClick}
          >
            {t('imei_demo.activate_warranty')}
          </button>

          <p style={{ marginTop: '20px', fontSize: '14px', lineHeight: 1.3 }}>
            Caso o aplicativo não esteja instalado, volte para esta página e clique novamente em &#34;Ativar&#34;.
          </p>

        </main>
      </div>
    );
  }


  // Second screen
  if (showSecondScreen) {
    return (
      <div className="imei-container">
        <main className="imei-main">
          <header className="imei-header">
            <img src={logo} alt="Logo do parceiro" className="imei-logo" />
          </header>

          <p>{t('imei_demo.click_the_button')}</p>

          <button
            className="button-86"
            role="button"
            style={{ marginTop: '50px' }}
            onClick={handleActiveClick}
          >
            {t('imei_demo.activate_warranty')}
            {loadingSubmit ? <LittleLoadingComponent /> : null}
          </button>
        </main>
      </div>
    );
  }

  if (showDialogIMEI) {
    return (
      <div className="modal-imei-overlay">

        <div className='modal-imei-content'>
          <div className='modal-imei-content-header'>
            <img src={IconDialogHeader} className='modal-imei-content-header-logo' />
            <img src={IconDialogClose} className='modal-imei-content-header-close' onClick={() => setShowDialogIMEI(false)} />
          </div>

          <div className='modal-imei-content-scroll'>

            <div>
              <label className="modal-imei-content-title">Veja como encontrar o IMEI no seu aparelho Samsung!</label>

              <div className='modal-imei-content-item'>
                <img className='modal-imei-content-item-icon' src={IconDialogDeviceSettings} />
                <label className='modal-imei-content-item-title'>Vá em <b>configurações.</b></label>
              </div>

              <div className='modal-imei-content-item'>
                <img className='modal-imei-content-item-icon' src={IconDialogDeviceAbout} />
                <label className='modal-imei-content-item-title'>Selecione a opção <b>sobre o telefone.</b></label>
              </div>

              <div className='modal-imei-content-item'>
                <img className='modal-imei-content-item-icon' src={IconDialogDeviceIMEI} />
                <label className='modal-imei-content-item-title'>Basta pressionar o IMEI <b>(slot1)</b> para copiar e depois colar no campo indicado.</label>
              </div>

              <img className='modal-imei-content-screen' src={ImgDialogScreenImei} />

            </div>

          </div>
        </div>
      </div>
    );
  }

  if (showDialogHelp) {
    return (
      <div className="modal-imei-overlay">

        <div className='modal-imei-content'>
          <div className='modal-imei-content-header'>
            <img src={IconDialogHeader} className='modal-imei-content-header-logo' />
            <img src={IconDialogClose} className='modal-imei-content-header-close' onClick={() => setShowDialogHelp(false)} />
          </div>

          <div className='modal-imei-content-scroll'>

            <div>
              <img src={IconDialogHelp} className='modal-imei-content-header-help-logo' />

              <label className="modal-imei-content-help-title">Está com dúvidas? Não se preocupe!</label>
              <label className="modal-imei-content-help-subtitle">Na SuperSim, algumas informações simplificam seu acesso ao crédito, eliminando algumas etapas.</label>
              <label className="modal-imei-content-help-explanation">Aqui está a explicação!</label>

              <div className='modal-imei-content-help-explanation-items'>

                <div className='modal-imei-content-help-item'>
                  <div style={{ marginTop: '10px', marginBottom: '6px' }} >
                    <img className='modal-imei-content-help-item-icon' src={IconDialogCheck} />
                    <label className='modal-imei-content-help-item-title'>Porquê tenho que fornecer essas informações?</label>
                  </div>
                  <label className='modal-imei-content-help-item-description'>Elas são usadas para ativar o sistema de garantia no seu celular, assegurando que você tenha a melhor condição de empréstimo.</label>
                </div>

                <div className='modal-imei-content-help-item'>
                  <div style={{ marginBottom: '6px' }} >
                    <img className='modal-imei-content-help-item-icon' src={IconDialogCheck} />
                    <label className='modal-imei-content-help-item-title'>O que acontece se o pagamento não for realizado?</label>
                  </div>
                  <label className='modal-imei-content-help-item-description'>Caso seu pagamento esteja em atraso, algumas funcionalidades do seu celular poderão ser temporariamente limitadas. Para restaurar o acesso completo, basta assistir a um anúncio promocional no aplicativo da SuperSim. Ao concluir, seu celular será liberado imediatamente. Esse processo poderá se repetir até a quitação total do débito pendente.</label>
                </div>

                <div className='modal-imei-content-help-item' style={{ marginBottom: '20px' }} >
                  <div style={{ marginBottom: '6px' }} >
                    <img className='modal-imei-content-help-item-icon' src={IconDialogCheck} />
                    <label className='modal-imei-content-help-item-title'>Como evitar que as restrições sejam ativadas?</label>
                  </div>
                  <label className='modal-imei-content-help-item-description'>Para garantir o uso completo do seu celular sem restrições, basta manter seus pagamentos em dia. Assim, você evita qualquer interrupção e pode aproveitar todas as funcionalidades normalmente.</label>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    );
  }

  // First screen
  return (
    <div className="imei-container">
      <main className="imei-main">
        <header className="imei-header">
          <img src={logo} alt="Logo do parceiro" className="imei-logo" />
        </header>

        <p style={{ marginTop: '50px' }}>{t('imei_demo.instruction')}</p>

        <form className="imei-form">
          <div className="imei-inputGroup" style={{ marginTop: '25px' }}>
            <label htmlFor="imei">{t('imei_demo.imei_label')}</label>
            <input
              id="imei"
              type="text"
              placeholder={t('imei_demo.imei_placeholder')}
              className="imei-input"
              value={imei}
              onChange={handleImeiChange}
            />
            {errors.imei && <span className="error-message">{errors.imei}</span>}

            <label
              className="imei-link"
              style={{ marginTop: '10px' }}
              onClick={(e) => {
                setShowDialogIMEI(true)
              }}
            >
              {t('imei_demo.how_to_find_imei')}
            </label>
          </div>

          <div className="imei-inputGroup" style={{ marginTop: '25px' }}>
            <label htmlFor="document">{t('imei_demo.document_label')}</label>
            <input
              id="document"
              type="text"
              placeholder={t('imei_demo.document_placeholder')}
              className="imei-input"
              value={userId}
              onChange={handleDocumentChange}
            />
            {errors.document && <span className="error-message">{errors.document}</span>}
          </div>

          <div className="bottom-container">
            <button
              className="button-86"
              role="button"
              style={{ marginBottom: '30px' }}
              disabled={isButtonDisabled}
              onClick={handleValidateUploadUser}
            >
              {t('imei_demo.activate_button')}
              {loadingSubmit ? <LittleLoadingComponent /> : null}

            </button>

            <label
              onClick={(e) => {
                setShowDialogHelp(true)
              }}>Porquê tenho que fornecer essas informações?</label>

          </div>
        </form>
      </main>
    </div>
  );
}

export default ImeiDemo;
